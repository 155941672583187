import React from 'react';
import { useQuery } from 'react-query';

import TabSwitch from '+containers/Dashboard/Shared/TabSwitch';
import { useFeedbackHandler, useSearchQuery } from '+hooks';
import APIRequest from '+services/api-services';

import AllMerchantTable from './AllMerchantTable';
import AllMerchantTeamMember from './MerchantTeamMember';

import './index.scss';

const api = new APIRequest();

const tabs = ['Merchant Accounts', 'Merchant Team Members'];

function MerchantsGeneral() {
  const { feedbackInit } = useFeedbackHandler();
  const searchQuery = useSearchQuery();
  const activeTab = searchQuery.value.subTab || tabs[0];

  const { data: merchantsStats } = useQuery(`MERCHANT_STATISTICS`, () => api.getMerchantStatistics(), {
    onError: () => {
      feedbackInit({
        message: `There has been an error fetching merchants statistics.`,
        type: 'danger'
      });
    }
  });

  return (
    <div className="element-box">
      <div className="element-box-heading settlements-settings__heading">
        <div className="heading-box-mmd">
          <h5 className="form-header">Merchants Overview</h5>
          <div className="form-desc no-underline mb-3">
            An overview of all the merchant’s with and without access to Korapay’s services.
          </div>
        </div>
      </div>
      <section className="merchants-summary">
        <div>
          <p>Total Merchants</p>
          <p>{merchantsStats?.total || '--'}</p>
          <p>All merchants who have signed up</p>
        </div>
        <div>
          <p>Active Merchants</p>
          <p>{merchantsStats?.status?.active || '--'}</p>
          <p>Merchants with service access</p>
        </div>
        <div>
          <p>Deactivated Merchants</p>
          <p>{merchantsStats?.status?.deactivated || '--'}</p>
          <p>Merchants without service access</p>
        </div>
      </section>
      <legend>
        <span />
      </legend>
      <div className="toggle-buttons">
        <TabSwitch options={tabs} activeTab={activeTab} setTab={tab => searchQuery.setQuery({ subTab: String(tab) }, true)} />
      </div>
      {activeTab === tabs[0] && <AllMerchantTable />}
      {activeTab === tabs[1] && <AllMerchantTeamMember />}
    </div>
  );
}

export default MerchantsGeneral;
