import React from 'react';

import { ICheckboxInput } from '+types';

export default function CheckboxInput({
  label,
  value,
  id,
  data,
  onChange,
  borderTop,
  setChecked,
  disabled = false,
  ableToDisableAllCheckboxes,
  disableAllChecboxesLabel,
  onChangeDisableAllCheckboxes,
}: ICheckboxInput) {
  const isDisableAllCheckboxesDisabled = !data?.length || data?.length === 1;
  return (
    <div className={`iam-modal__form-group ${borderTop ? 'top-border' : ''}`}>
      {label && <label htmlFor={id}>{label}</label>}
      {ableToDisableAllCheckboxes && (
        <div className="iam-modal__form-check">
          <input type="checkbox" disabled={isDisableAllCheckboxesDisabled} checked={disabled} onChange={() => onChangeDisableAllCheckboxes?.(!disabled)} />
          <label className={isDisableAllCheckboxesDisabled ? 'disabled' : ''}>{disableAllChecboxesLabel}</label>
        </div>
      )}
      {data?.map(each => (
        <div key={each.value} className="iam-modal__form-check">
          <input
            data-testid="checkbox"
            type="checkbox"
            id={each.value}
            name={each.value}
            onChange={e => {
              if (e.target.checked) {
                setChecked([...(value as string[]), each.value]);
              } else {
                setChecked((value as string[]).filter(value => value !== each.value));
              }
            }}
            checked={(value as string[]).includes(each.value)}
            disabled={disabled}
          />
          <label className={disabled ? 'disabled' : ''} htmlFor={each.value}>
            {each.label}
          </label>
        </div>
      ))}
    </div>
  );
}
