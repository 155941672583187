import React from 'react';
import './RiskLevel.scss';

export interface IRiskLevelProps {
  riskLevel: string;
  hideTitle?: boolean;
  className?: string;
  hideDivider?: boolean;
}

const RiskLevel = ({ riskLevel, hideTitle, hideDivider, className }: IRiskLevelProps) => {
  const switchRisk = {
    low_risk: 'low',
    medium_risk: 'medium',
    above_average_risk: 'above-average',
    high_risk: 'high'
  };
  const risk = switchRisk[riskLevel];
  return (
    <>
      <span className={`risk-level align-items-center ${className || ''}`}>
        <span
          className={`risk-level--indicator risk-level--indicator-${
            risk === 'above-average' ? 'semi-high' : risk
          } d-flex align-items-center`}
        >
          <span />
          <span />
          <span />
          <span />
        </span>
        {!hideTitle && <span className="risk-level--title ml-2">{risk === 'above-average' ? 'semi-high' : risk} risk</span>}
      </span>
      {!hideDivider && <span className="risk-level--divider" />}
    </>
  );
};


export default RiskLevel;
