import React, { useRef, useState } from 'react';
import { BulkActionType, MapTypes, TableSubtypes, TableTypes, TTypes } from 'src/types/tables';

import { useFeedbackHandler } from '+hooks';
import APIRequest from '+services/api-services';
import Modal from '+shared/Modal';
import useBulkActionStore from '+store/zustand';
import { BulkInfoType, FileFormatType } from '+types';
import { capitalize, capitalizeRemovedash, logError } from '+utils';

import AdvanceExportModal from './AdvanceExportModal';
import { PausedPaymentsModalContent } from './data/PausedPayments';
import { maxNumOfCheckBoxToBeSelected, PayOutReversalBulkModalContents } from './data/PayoutReversalBulkModalDescription';

import './index.scss';

const api = new APIRequest();

export type BulkActionProps<T> = {
  type: string;
  subType: string;
  isCompleted: boolean;
  toggleActionCompleted: (value?: boolean) => void;
  exportAction: (format: FileFormatType, close: () => void, FieldsToExport: string | string[]) => void;
  totalCount: number;
  clearSelection: () => void;
  selectedRows: T[];
  disableBulkAction: boolean;
  action: string;
  hideExport?: boolean;
  showModal?: boolean;
  onSuccessBulkAction?: () => void;
  showSpinnerIconAction?: () => void;
};
type TMapTypesToActions = Partial<Record<TableTypes | TableSubtypes, MapTypes[]>>;

const mapToActionWithoutModal: Record<string, TTypes[]> = {
  retry_generate_report: [
    {
      type: 'retry',
      actionKey: 'retry_generate_report',
      action: api.retryFailedSpoolingTransactionReportService,
      errorMessage: 'There has been an error generating a report'
    }
  ]
};
const mapTypesToActions: TMapTypesToActions = {
  payins: [
    {
      type: 'refund',
      action: api.sendBulkAction,
      actionType: 'refund',
      modalHeading: 'Refund Payin(s)',
      modalDescription: 'Please confirm that you want to refund the selected payin(s). Note that this action cannot be undone.'
    }
  ],
  pending_settlements: [
    {
      type: 'approve',
      action: api.sendBulkAction,
      actionType: 'settlement_approval',
      modalHeading: 'Approve Settlement(s)',
      modalDescription: 'Please confirm that you want to approve the selected settlement(s). Note that this action cannot be undone.'
    }
  ],
  paused_payments: [
    {
      type: 'cancel selected',
      action: api.unpausePayment,
      actionType: 'cancel_multiple',
      modalHeading: 'Cancel All Paused Payments?',
      modalDescription: PausedPaymentsModalContent
    },
    {
      type: 'process selected',
      action: api.unpausePayment,
      actionType: 'process_multiple',
      modalHeading: 'Process All Paused Payments?',
      modalDescription: PausedPaymentsModalContent,
      btnClass: 'approve-btn'
    }
  ],
  ready_settlements: [
    {
      type: 'approve',
      action: api.sendBulkAction,
      actionType: 'settlement_approval',
      modalHeading: 'Approve Settlement(s)',
      modalDescription: 'Please confirm that you want to approve the selected settlement(s). Note that this action cannot be undone.'
    }
  ],
  approved_settlements: [
    {
      type: 'settle',
      action: api.sendBulkAction,
      actionType: 'settlement_processing',
      modalHeading: 'Settle Settlement(s)',
      modalDescription: 'Please confirm that you want to settle the selected settlement(s). Note that this action cannot be undone.'
    }
  ],
  payout_reversals: [
    {
      type: 'Approve Reversals',
      action: api.sendBulkAction,
      actionType: 'payout_reversal_approve',
      modalHeading: 'Approve Bulk Reversals',
      modalDescription: PayOutReversalBulkModalContents,
      isMaxCheckBoxSelected: true
    },
    {
      type: 'Decline Reversals',
      action: api.sendBulkAction,
      actionType: 'payout_reversal_decline',
      modalHeading: 'Decline Bulk Reversals',
      modalDescription: PayOutReversalBulkModalContents,
      isMaxCheckBoxSelected: true,
      secondBottonColorCode: '#F32345'
    }
  ]
};

const BulkAction = <T,>(props: BulkActionProps<T>): JSX.Element => {
  const { hideExport = false, showModal = true } = props;
  const { feedbackInit } = useFeedbackHandler();
  const activeAction: React.MutableRefObject<MapTypes> = useRef({
    type: '',
    actionType: '',
    modalHeading: '',
    modalDescription: '',
    action: () => Promise.resolve()
  });
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [showExport, setOpenExport] = useState<boolean>(false);
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const bulkInfo = useBulkActionStore((state: { bulkInfo: BulkInfoType<T> }) => state.bulkInfo);
  const bulkState = bulkInfo?.data?.filter((item: BulkActionType<T>) => item.type === props.action);
  const isProcessing = bulkState?.some((item: BulkActionType<T>) => item.status === 'in_progress');

  const onCloseModal = () => {
    setModalVisible(false);
  };

  const onOpenModal = (key: MapTypes) => {
    activeAction.current = key;
    setModalVisible(true);
  };

  const getPayloadStructure = (data: any, key: TTypes | TMapTypesToActions): any => {
    switch (key.actionKey || key.actionType) {
      case 'retry_generate_report':
        return { retry_ids: data };
      case 'cancel_multiple':
        return { action: 'cancel', references: data };
      case 'process_multiple':
        return { action: 'process', references: data };
      default:
        return { type: activeAction.current.actionType, references: data };
    }
  };

  const managedContentProps = (key: TTypes | TMapTypesToActions): any => {
    switch (key.actionKey || key.actionType) {
      case 'cancel_multiple':
        return { refs: props.selectedRows, stage: 'process_multiple' };
      case 'process_multiple':
        return { refs: props.selectedRows, stage: 'process_multiple' };
      case 'payout_reversal_approve':
        return { selectedRows: props.selectedRows, actionMode: 'approve' };
      case 'payout_reversal_decline':
        return { selectedRows: props.selectedRows, actionMode: 'decline' };
      default:
        return {};
    }
  };

  const managedMaxCheckBoxLimit = (key: MapTypes): boolean => {
    if (key.actionType === 'payout_reversal_approve' || key.actionType === 'payout_reversal_decline') {
      return props.selectedRows.length > maxNumOfCheckBoxToBeSelected;
    }
    return false;
  };
  const handleActionWithoutModal = async (key: TTypes) => {
    try {
      if ('actionKey' in key) {
        setButtonLoading(true);
        if (props.showSpinnerIconAction) {
          props.showSpinnerIconAction();
        }
        const response = await mapToActionWithoutModal[key.actionKey][0].action(getPayloadStructure(props.selectedRows, key));
        if (response && response.message === 'success' && props.onSuccessBulkAction) {
          props.onSuccessBulkAction();
        }
      }
    } catch (error) {
      logError(error);
      feedbackInit({
        message: key.errorMessage,
        type: 'danger'
      });
    } finally {
      props.clearSelection();
      setButtonLoading(false);
    }
  };

  const bulkActions = showModal
    ? mapTypesToActions[(props.subType || props.type) as unknown as keyof TMapTypesToActions]
    : mapToActionWithoutModal[props.subType || props.type];

  const handleAction = async (): Promise<void> => {
    try {
      await activeAction.current.action(getPayloadStructure(props.selectedRows, activeAction.current));
    } catch (error) {
      logError(error);
      feedbackInit({
        message: 'Error performing this bulk action.',
        type: 'danger'
      });
    } finally {
      onCloseModal();
      props.clearSelection();
    }
  };

  const getExportHeaders = () => {
    return {
      heading: 'Export Selection',
      description: 'Choose how you would like to export these users'
    };
  };
  const Content = activeAction.current?.modalDescription;

  const contentProps = managedContentProps(activeAction.current);
  return (
    <section>
      <div className="basic-filter">
        <div className="element-actions-tab">
          <div>
            <span data-testid="count-span">{props.totalCount} item(s) selected</span>
            <span className="divider-sm" />
            <button type="button" onClick={props.clearSelection} data-testid="clear-btn">
              <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" fill="none" viewBox="0 0 21 20">
                <path
                  fill="#AABDCE"
                  d="M3.426 2.926c3.902-3.9 10.247-3.9 14.149 0 3.9 3.901 3.9 10.248 0 14.15A9.976 9.976 0 0110.5 20a9.975 9.975 0 01-7.074-2.924c-3.901-3.902-3.901-10.249 0-14.15zM6.374 12.95a.833.833 0 101.179 1.178L10.5 11.18l2.946 2.948a.835.835 0 001.18-1.18l-2.947-2.946 2.947-2.948a.833.833 0 10-1.179-1.179L10.5 8.822 7.553 5.874a.833.833 0 10-1.18 1.18L9.322 10l-2.947 2.948z"
                />
              </svg>
              &nbsp;
              <span>Clear</span>
            </button>
          </div>
          {!hideExport && (
            <div>
              <button type="button" onClick={() => setOpenExport(true)} data-testid="bulk-export-btn">
                <i className="os-icon os-icon-arrow-up-right" />
                &nbsp;
                <span>Export Selection</span>
              </button>
            </div>
          )}
        </div>
      </div>
      {!bulkActions?.length && <p>There are no bulk actions for this table.</p>}
      {bulkActions?.length && (
        <div className="bulk-action-section" data-testid="action-btn-wrapper">
          {bulkActions.map((key, index: number) => (
            <button
              type="button"
              key={index}
              className={`btn btn-md active ${key.btnClass || ''}`}
              onClick={() => (showModal ? onOpenModal(key) : handleActionWithoutModal(key))}
              disabled={props.disableBulkAction || (isProcessing && props.action === key.actionType)}
            >
              {buttonLoading ? (
                <span className="spinner-border spinner-border-sm" style={{ marginRight: '0.5rem' }} role="status" aria-hidden="true" />
              ) : (
                capitalizeRemovedash(key.type)
              )}
            </button>
          ))}
        </div>
      )}

      <AdvanceExportModal
        openExport={showExport}
        setOpenExport={value => setOpenExport(value)}
        exportAction={props.exportAction}
        type={props.type}
        close={() => setOpenExport(false)}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...getExportHeaders()}
      />

      <Modal
        visible={modalVisible}
        close={onCloseModal}
        secondaryCompletedModal
        heading={activeAction.current.modalHeading}
        // eslint-disable-next-line react/jsx-props-no-spreading
        description={typeof Content === 'function' ? <Content {...contentProps} /> : <p className="approveText-p">{Content}</p>}
        size="base"
        formCenter={false}
        firstButtonText="Cancel"
        firstButtonDisable={activeAction.current.isMaxCheckBoxSelected ? managedMaxCheckBoxLimit(activeAction.current) : false}
        secondButtonText={`Yes, ${capitalize(activeAction.current.type)}`}
        secondButtonDisable={activeAction.current.isMaxCheckBoxSelected ? managedMaxCheckBoxLimit(activeAction.current) : false}
        headerBottomBorder
        equalFooterBtn
        secondButtonActionIsTerminal={false}
        secondButtonAction={handleAction}
        secondButtonColor={activeAction.current.secondBottonColorCode || undefined}
      />
    </section>
  );
};

export default BulkAction;
