/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-use-before-define */
import React, { useState } from 'react';
import { useMutation } from 'react-query';
import { useFormik } from 'formik';

import FieldErrorMsg from '+containers/Dashboard/Shared/FormError';
import Modal, { IModalProps } from '+containers/Dashboard/Shared/Modal';
import { useFeedbackHandler } from '+hooks';
import APIRequest from '+services/api-services';
import { CycleInitialValuesType, CyclePayloadType, CycleType, IBillingCycleEditorModal, IError } from '+types';
import { logError, stripNonNumeric } from '+utils';

import OverlayInput from './OverlayInput';

type BCEMStageType = 'setCycle' | 'confirmCycle';

const api = new APIRequest();

export default function BillingCycleEditorModal({ onClose, currentData, currency, refetch }: IBillingCycleEditorModal) {
  const [step, setStep] = useState<BCEMStageType>('setCycle');
  const [actionIsConfirmed, setActionIsConfirmed] = useState(false);
  const { closeFeedback, feedbackInit } = useFeedbackHandler();

  const formik = useFormik({
    initialValues: { type: currentData.type, day: currentData.day } as CycleInitialValuesType,
    validate: formValues => {
      const errors: Partial<Omit<CycleInitialValuesType, 'type'> & { type: string }> = {};

      if (!formValues.type) errors.type = 'Cycle type is required';
      if (!formValues.day) errors.day = 'Value cannot be empty';
      if (Number(formValues.day) > 28) errors.day = 'Value cannot exceed 28 days';
      if (Number(formValues.day) <= 0) errors.day = 'Value cannot be 0 or less';

      return errors;
    },
    onSubmit: async () => {
      switch (step) {
        case 'setCycle':
          setStep('confirmCycle');
          break;
        case 'confirmCycle':
          await handleCycleChange();
          break;
        default:
          break;
      }
    }
  });

  const overlayText = getOverlayText(currentData?.type ?? formik.values.type);

  const setCycle = useMutation((payload: CyclePayloadType) => api.setBillingCyle(payload), {
    onSuccess: () => refetch(),
    onError: (error: IError) => {
      logError(error);
      feedbackInit({
        message: error.response?.data?.message || 'Unable to set billing cycle. Please try again',
        type: 'danger',
        componentLevel: true
      });
      setTimeout(() => {
        closeFeedback();
      }, 5000);
    }
  });

  const handleCycleChange = async () => {
    await setCycle.mutateAsync({ ...formik.values, currency });
  };

  const billingCycleEditor = (
    <div className="billing-cycle-modal__content">
      <label htmlFor="combo-control" className="billing-cycle-modal__label">
        Billing details
      </label>
      <div className="d-flex align-items-baseline billing-cycle-modal__controls">
        <div>
          <select
            id="combo-control"
            className="form-control"
            aria-labelledby="billing_details_desc"
            {...formik.getFieldProps('type')}
            data-state={formik.touched.type && formik.errors.type ? 'invalid' : ''}
          >
            <option value="">--Select type--</option>
            {/* <option value="static" hidden={currentData.type === 'dynamic'}> */}
            <option value="static">Per Month</option>
            {/* <option value="dynamic" hidden={currentData.type === 'static'}> */}
            <option value="dynamic" hidden>
              Per Cycle
            </option>
          </select>
          <FieldErrorMsg error={formik.errors.type as string} id={`${formik.values.type}`} touched />
        </div>

        <OverlayInput
          overlayText={overlayText}
          name="day"
          value={formik.values.day}
          onChange={e => formik.setFieldValue('day', stripNonNumeric(e.target.value))}
          maxLength={2}
          className="form-control"
          touched
          error={formik.errors.day as string}
          id="combo-control"
        />
      </div>
    </div>
  );

  const confirmation = (
    <div className="d-flex align-items-baseline billing-config__confirm-action-wrapper">
      <label htmlFor="confirm_action">Yes I understand the implications of this action</label>
      <input
        id="confirm_action"
        type="checkbox"
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setActionIsConfirmed(e.target.checked)}
        name="confirm_action"
        checked={actionIsConfirmed}
      />
    </div>
  );

  const modalProps: Record<'shared' | BCEMStageType, IModalProps | Partial<IModalProps>> = {
    shared: {
      close: onClose,
      secondButtonAction: formik.handleSubmit,
      secondButtonActionIsTerminal: false,
      secondaryCompletedModal: true
    },
    setCycle: {
      size: 'md',
      heading: 'Change Billing Cycle/Date',
      description: 'Modifying the default billing cycle/date for card issuance will apply to all merchants with access to the service.',
      content: billingCycleEditor
    },
    confirmCycle: {
      size: 'sm',
      heading: 'Confirm change(s)',
      description: 'Once you confirm, billing cycle/date for card issuance will apply to all merchants with access to the service.',
      content: confirmation,
      secondButtonActionIsTerminal: true,
      secondButtonDisable: !actionIsConfirmed,
      secondButtonAction: handleCycleChange,
      completedDescription: 'You have successfully changed card issuance billing date'
    }
  };

  const mergedProps = {
    ...(modalProps.shared as IModalProps),
    ...modalProps[step]
  };

  return <Modal {...mergedProps} />;
}

const getOverlayText = (value: CycleType) => {
  if (value === 'static') return 'Every Month';
  if (value === 'dynamic') return '+T';
  return '';
};
