import React, { useEffect } from 'react';

interface IFeedbackActionType {
  name: string;
  action: () => void;
}

export interface IFeedbackPropsType {
  statusCode?: number;
  isActive?: boolean;
  isClosable?: boolean;
  componentLevel?: boolean;
  title?: React.ReactNode;
  message?: React.ReactNode;
  type: 'danger' | 'warning' | 'success';
  action?: IFeedbackActionType;
  callback?: (...args: Array<unknown | undefined>) => void;
}

/**
 * @description This custom hook manages the status information of an asynchronous event and provides optional actions.
 * It is designed to work in conjunction with the FeedbackHandler.tsx component.
 *
 * @returns {Object} An object containing two functions:
 * - closeFeedback: Terminates FeedbackHandler, removing it from display and resetting its state.
 * - feedbackInit: Initializes FeedbackHandler with optional information to be displayed on the screen.
 *
 * @example
 * const { closeFeedback, feedbackInit } = useFeedbackHandler();
 *
 * // Example of initializing FeedbackHandler:
 * feedbackInit({
 *   status: 200,
 *   isClosable: true,
 *   title: "Transaction received",
 *   action: () => console.log("success!"),
 *   type: "success",
 *   componentLevel: true
 * });
 */

const getInitialState = (): IFeedbackPropsType => ({
  statusCode: undefined,
  isActive: false,
  isClosable: true,
  componentLevel: false,
  title: '',
  message: '',
  type: 'success',
  action: undefined,
  callback: undefined
});
const reducerFn = (prev: IFeedbackPropsType, next: Partial<IFeedbackPropsType>) => ({
  ...prev,
  ...next
});

const listeners: Array<(state: IFeedbackPropsType) => void> = [];

let stateInMemory = { ...getInitialState() };

const dispatch = (newState: Partial<IFeedbackPropsType>) => {
  stateInMemory = reducerFn(getInitialState(), newState);
  listeners.forEach((listener) => listener(stateInMemory));
};

const feedbackInit = (props: Partial<IFeedbackPropsType>) => {
  dispatch({ ...props, isActive: true });
};

const closeFeedback = () => {
  dispatch({ ...getInitialState() });
};

const useFeedbackHandler = () => {
  const [data, setData] = React.useState(stateInMemory);

  useEffect(() => {
    listeners.push(setData);
    return () => {
      const listenerIndex = listeners.indexOf(setData);
      if (listenerIndex > -1) listeners.splice(listenerIndex, 1);
    };
  }, []);

  return {
    data,
    closeFeedback,
    feedbackInit
  };
};

export default useFeedbackHandler;
