import React from 'react';

import { TeamMemberType, TeamPermissionColumnType } from '+types';
import { capitalizeRemovedash } from '+utils';

import serializeSelectedPermission from './data';

import './index.scss';

const PermissionsTable: React.FC<{
  role: TeamMemberType['userRole'];
}> = ({ role }) => {
  return (
    <div className="team-permission-table-wrapper">
      <table>
        <thead>
          <tr>
            <th>Permissions</th>
            <th>View</th>
            <th>Export</th>
            <th>Manage</th>
          </tr>
        </thead>
        <tbody>
          {Object.keys(role?.permissions).map(title => (
            <tr key={title}>
              <td>{capitalizeRemovedash(title)}</td>
              {Object.keys(TeamPermissionColumnType).map(permission => (
                <td key={permission} aria-label="permission">
                  <input
                    type="checkbox"
                    checked={serializeSelectedPermission(role?.permissions?.[title]).includes(permission)}
                    className="permission-checkbox"
                    disabled
                  />
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default PermissionsTable;
