import React, { useEffect } from 'react';

import { useLargeExportDownloader, useSearchQuery, useSetUserAccess } from '+hooks';
import { isAllowed, queriesParams } from '+utils';

import MerchantProductAccessLimits from './MerchantProductAccessLimits';
import MerchantsCompliance from './MerchantsCompliance';
import MerchantsGeneral from './MerchantsGeneral';
import MerchantsPaymentPreferences from './MerchantsPaymentPreferences';

function MerchantsComponent() {
  const userAccess = useSetUserAccess();
  const searchQuery = useSearchQuery();
  const { getDownload } = useLargeExportDownloader('Merchant Balance History');
  const activeTab = searchQuery.value.tab || '';

  useEffect(() => {
    if (isAllowed(userAccess, ['merchants.view', 'merchants.export'])) {
      searchQuery.setQuery({ tab: searchQuery?.value?.tab || queriesParams.general });
    } else if (isAllowed(userAccess, ['merchant_compliance.view'])) {
      searchQuery.setQuery({ tab: searchQuery?.value?.tab || queriesParams.compliance });
    } else if (isAllowed(userAccess, ['bank_transfer_settings_requests.view'])) {
      searchQuery.setQuery({ tab: searchQuery?.value?.tab || queriesParams['payment-preferences'] });
    } else {
      searchQuery.setQuery({ tab: searchQuery?.value?.tab || 'product-access-limit' });
    }
  }, [userAccess]);

  useEffect(() => {
    if (!searchQuery.value.tab) {
      if (isAllowed(userAccess, ['merchants.view', 'merchants.export'])) {
        searchQuery.setQuery({ tab: searchQuery?.value?.tab || 'general' });
      } else if (isAllowed(userAccess, ['merchant_compliance.view'])) {
        searchQuery.setQuery({ tab: searchQuery?.value?.tab || queriesParams.compliance });
      } else if (isAllowed(userAccess, ['bank_transfer_settings_requests.view'])) {
        searchQuery.setQuery({ tab: searchQuery?.value?.tab || queriesParams['payment-preferences'] });
      } else {
        searchQuery.setQuery({ tab: searchQuery?.value?.tab || 'product-access-limit' });
      }
    }
  }, [searchQuery.value.tab]);

  useEffect(() => {
    getDownload();
  }, []);

  if (!userAccess) return <div />;

  return (
    <div className="content-i">
      <div className="content-box">
        <div className="os-tabs-w">
          <div className="os-tabs-controls os-tabs-complex">
            <ul className="nav nav-tabs">
              {isAllowed(userAccess, ['merchants.view', 'merchants.export']) && (
                <li className="nav-item">
                  <span
                    role="button"
                    aria-expanded="false"
                    className={activeTab === 'general' ? 'nav-link active' : 'nav-link'}
                    data-toggle="tab"
                    tabIndex={0}
                    onClick={() => searchQuery.setQuery({ tab: 'general' }, true)}
                    onKeyDown={() => searchQuery.setQuery({ tab: 'general' }, true)}
                  >
                    <span className="tab-label">General</span>
                  </span>
                </li>
              )}
              {isAllowed(userAccess, ['merchant_compliance.view']) && (
                <li className="nav-item">
                  <span
                    role="button"
                    aria-expanded="false"
                    className={activeTab === 'compliance' ? 'nav-link active' : 'nav-link'}
                    data-toggle="tab"
                    tabIndex={0}
                    onClick={() => searchQuery.setQuery({ tab: 'compliance' }, true)}
                    onKeyDown={() => searchQuery.setQuery({ tab: 'compliance' }, true)}
                  >
                    <span className="tab-label">Compliance</span>
                  </span>
                </li>
              )}
              {isAllowed(userAccess, ['bank_transfer_settings_requests.view']) && (
                <li className="nav-item">
                  <span
                    role="button"
                    aria-expanded="false"
                    className={activeTab === 'payment-preferences' ? 'nav-link active' : 'nav-link'}
                    data-toggle="tab"
                    tabIndex={0}
                    onClick={() => searchQuery.setQuery({ tab: 'payment-preferences', status: 'pending' }, true)}
                    onKeyDown={() => searchQuery.setQuery({ tab: 'payment-preferences', status: 'pending' }, true)}
                  >
                    <span className="tab-label">Payment Preferences</span>
                  </span>
                </li>
              )}
              <li className="nav-item">
                <span
                  role="button"
                  aria-expanded="false"
                  className={activeTab === 'product-access-limit' ? 'nav-link active' : 'nav-link'}
                  data-toggle="tab"
                  tabIndex={0}
                  onClick={() => searchQuery.setQuery({ tab: 'product-access-limit' }, true)}
                  onKeyDown={() => searchQuery.setQuery({ tab: 'product-access-limit' }, true)}
                >
                  <span className="tab-label">Product Access & Limits</span>
                </span>
              </li>
            </ul>
          </div>
          <div>
            {activeTab === 'general' && <MerchantsGeneral />}
            {activeTab === 'compliance' && <MerchantsCompliance />}
            {activeTab === 'payment-preferences' && <MerchantsPaymentPreferences />}
            {activeTab === 'product-access-limit' && <MerchantProductAccessLimits />}
          </div>
        </div>
      </div>
    </div>
  );
}

export default MerchantsComponent;
