import { TeamPermissionColumnType } from '+types';

export default (permission?: string): string[] => {
  const { view, export: exportPermission, manage } = TeamPermissionColumnType;
  switch (permission) {
    case TeamPermissionColumnType.export:
      return [view, exportPermission];

    case TeamPermissionColumnType.manage:
      return [view, exportPermission, manage];

    case TeamPermissionColumnType.view:
      return [view];

    default:
      return [];
  }
};
