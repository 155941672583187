import React from 'react';
import { useQuery } from 'react-query';

import { useFeedbackHandler, useSearchQuery, useSetUserAccess } from '+hooks';
import APIRequest from '+services/api-services';
import Icon from '+shared/Icons';
import Table from '+shared/Table';
import ToolTip from '+shared/Tooltip';
import { FileFormatType, IMerchant, IProductLimitData } from '+types';
import {
  APIDownload,
  capitalize,
  capitalizeRemovedash,
  filteredOutObjectProperty,
  getDate,
  getTime,
  isAllowed,
  logError,
  queriesParams,
  switchStatus
} from '+utils';

import BusinessAvatar from '+assets/theme/img/biz-avatar.png';

import './index.scss';

const api = new APIRequest();

function AllMerchantTable() {
  const { feedbackInit } = useFeedbackHandler();
  const searchQuery = useSearchQuery();
  const page = searchQuery.value.page ?? 1;
  const limit = searchQuery.value.limit ?? 10;

  const valuesToBeRemoved = [queriesParams.tab, queriesParams.page, queriesParams.limit, queriesParams.subTab];
  const sortingParams = {
    ...filteredOutObjectProperty(searchQuery.value, valuesToBeRemoved)
  };

  const userAccess = useSetUserAccess();

  const { data: merchants, isFetching: allMerchantsLoading } = useQuery(
    ['MERCHANTS', page, limit, sortingParams],
    () => api.getMerchants('', Number(page), sortingParams, String(limit)),
    {
      refetchOnMount: true,
      onError: error => {
        logError(error);
        feedbackInit({
          message: 'There has been an error fetching all merchants, Please try again',
          type: 'danger'
        });
      }
    }
  );

  const allMerchants = merchants?.data ?? [];

  const { data: allTierLevels } = useQuery(['TIER_LEVELS'], () => api.getAllTierLevels(), {
    onError: () => {
      feedbackInit({
        message: 'There has been an error fetching tier levels.',
        type: 'danger'
      });
    },
    select: data => {
      const responseData = data?.data as IProductLimitData[];

      return responseData
        ?.filter?.(tier => tier?.name?.toLowerCase() !== 'none')
        .map(tier => ({ value: tier.id, label: capitalize(tier.name) }));
    }
  });

  const isFetching = allMerchantsLoading;

  const renderPagination = () => {
    return [allMerchants.paging?.total_items, allMerchants.paging?.page_size];
  };

  const paginate = (currentPage: number) => {
    searchQuery.setQuery({ page: currentPage.toString() });
  };

  const exportMerchantList = async (format: FileFormatType, close: () => void, fieldsToExport: string | string[]) => {
    try {
      const res = await api.exportMerchants(fieldsToExport, format, sortingParams);
      const type = format === 'csv' ? 'csv' : 'xlsx';
      APIDownload(res, `All Merchant list at ${getDate(Date.now())}`, type);
      feedbackInit({
        title: 'Export Successful',
        message: (
          <>
            {' '}
            - Successfully exported <strong>{renderPagination()[0]} records.</strong>
          </>
        ),
        type: 'success'
      });
      close();
    } catch (error) {
      logError(error);
      feedbackInit({
        title: 'Export Failed',
        message: `There has been an error downloading your merchant list file`,
        type: 'danger',
        componentLevel: true
      });
    }
  };

  const Merchants = {
    annotation: 'merchant(s)',
    className: '--merchant-table',
    emptyStateHeading: 'No Merchants yet',
    data: allMerchants?.data || [],
    emptyStateMessage: 'There are no Merchants',
    rowURL: '/dashboard/merchant',
    rowKey: 'id',
    fields: (each: IMerchant) => ({
      data: {
        Merchant: (
          <>
            <img alt="business avatar" src={BusinessAvatar} />
            <span id="merchant-name">{capitalize(each.name)}</span>
          </>
        ),
        Status: (
          <>
            <span className={`status-pill smaller ${switchStatus(each.status)}`} />
            <span>{capitalizeRemovedash(each.status || 'N/A')}</span>
          </>
        ),
        merchant_id: each.id,
        Contact: <span className="lighter">{each.email}</span>,
        Tier: <span className="lighter">{capitalize(each?.tier_level?.name) || 'N/A'}</span>,
        Date_Created: (
          <>
            <span>{getDate(each.created_at)}</span>
            <span className="annotation">{getTime(each.created_at)}</span>
          </>
        )
      }
    })
  };

  return (
    <div className="row">
      <div className="col-sm-12">
        <div className="element-wrapper">
          <div className="row">
            <div className="col-sm-12">
              <div className="element-wrapper pt-0">
                <div className="element-box-tp">
                  <Table
                    annotation={Merchants?.annotation}
                    className={Merchants.className}
                    loading={isFetching}
                    data={Merchants?.data || []}
                    renderFields
                    hasPagination
                    rowKey={Merchants?.rowKey}
                    rowURL={Merchants?.rowURL}
                    tableHeadings={['Merchant', 'Status', 'Merchant ID', 'Contact', 'Tier', 'Date Created']}
                    tableHeaderIcon={{
                      tier: (
                        <ToolTip
                          className="merchant-tier-tooltip-filter-tier tier-tooltip-text"
                          contentClassName="merchant-tier-tooltip-filter-content"
                          type="tier info"
                          message="Merchants are grouped into tiers according to their product access and limits"
                        >
                          <Icon name="infoQuestionMark" width={11} height={11} className="tooltip-tab" />
                        </ToolTip>
                      )
                    }}
                    current={Number(page)}
                    totalItems={renderPagination()[0]}
                    pageSize={renderPagination()[1]}
                    tableWrapperClassName="table-responsive table-wrapper general-merchant-table-wrapper"
                    type="merchant"
                    filterKeywordPlaceholder="Search..."
                    filterQueryIDPlaceholder="Merchant ID"
                    filterExportAction={exportMerchantList}
                    filterTotalCount={renderPagination()[0]}
                    filterShowExport={isAllowed(userAccess, ['merchants.export']) as boolean}
                    filterOptions={[{ label: 'All Tiers', value: '' }, ...(allTierLevels || [])]}
                    actionFn={currentPage => paginate(currentPage)}
                    limitAction={currentLimit => searchQuery.setQuery({ limit: String(currentLimit) })}
                    emptyStateHeading={Merchants?.emptyStateHeading || ''}
                    emptyStateMessage={Merchants.emptyStateMessage || ''}
                    subType="merchant_general"
                    storedState={{ state: userAccess }}
                  >
                    {Merchants?.fields}
                  </Table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AllMerchantTable;
