import React, { useState } from 'react';
import { history, getDate, getTime, capitalize, capitalizeFirst,logError } from '+utils';
import PaginationComponent from '+shared/Pagination';
import WebhooksDetailsModal from './WebhooksDetailsModal';
import ellipsis from '+assets/img/dashboard/ellipsis.svg';
import '../index.scss';

interface IWebhooksTableComponentProps {
  data: Array<object>;
  paging: object;
  pagingAction: () => void;
  limitAction: () => void;
  savedState: any;
  scrollToTop?: () => void;
}
function WebhooksTableComponent({ data, paging, pagingAction, limitAction, savedState, scrollToTop }: IWebhooksTableComponentProps) {
  const [webhooksModalState, setWebhooksModalState] = useState({
    visible: false,
    data: {}
  });

  const queryParams = new URLSearchParams({ origin: 'web-hook' });
  const switchStatus = status => {
    switch (status) {
      case 'delivered':
        return 'green';
      case 'failed':
        return 'red';
      case 'processing':
        return 'yellow';
      default:
        return 'light-grey';
    }
  };
  const navigateToPayInOrPayoutDetailPage = (type: string, reference: string) => {
    try {
      if (type && reference) {
        const path = `/dashboard/${type === 'payin' ? 'pay-ins' : 'payouts'}/${reference}`;
        history.push(`${path}?${queryParams.toString()}`, null, {
          type: 'history',
          data: { ...savedState }
        });
      }
    } catch (error) {
      logError(error);
    }
  };

  const WebhookNotifications = () => {
    return (
      <section className="webhooks-table">
        <div className="div-table --webhooks-table --heading">
          <div>Status</div>
          <div>Transaction ID</div>
          <div>Type</div>
          <div>Merchant</div>
          <div>Attempts</div>
          <div>Last Attempt At</div>
        </div>

        {data?.map(each => (
          <div
            key={`transaction_${each.transaction_reference}`}
            className="div-table --webhooks-table --row"
            onClick={() =>
              setWebhooksModalState(prevState => ({
                ...prevState,
                visible: true,
                data: each
              }))
            }
            onKeyDown={() =>
              setWebhooksModalState(prevState => ({
                ...prevState,
                visible: true,
                data: each
              }))
            }
            
            tabIndex={0}
          >
            <div>
              <span className="body-row-header">Status: </span>
              {each.status === 'pending' ? (
                <>
                  <span className="status--text --grey">Pending...</span>
                </>
              ) : (
                <div className="--flex py-0">
                  <span className={`status--code ${switchStatus(each.status)}`}>
                    {each.status !== 'processing' ? each.response_code : <img src={ellipsis} alt="processing" aria-hidden />}
                  </span>
                  <span className={`status-pill smaller ${switchStatus(each.status)}`} />
                  <span className="status--text">{capitalizeFirst(each.status)}</span>
                </div>
              )}
            </div>
            <div>
              <span className="body-row-header">Transaction ID: </span>

              <button
                onClick={() => navigateToPayInOrPayoutDetailPage(each?.type, each?.transaction_reference)}
                type="button"
                className="trxn-id"
                aria-label="navigate-button"
              >
                {capitalize(each?.transaction_reference)}
              </button>
            </div>
            <div>
              <span className="body-row-header">Type: </span>
              <span className="--dark-grey">{each.type === 'payin' ? 'Pay-in' : 'Payout'}</span>
            </div>
            <div>
              <span className="body-row-header">Merchant: </span>
              <span>{each.merchantName}</span>
            </div>
            <div>
              <span className="body-row-header">Attempts: </span>
              <span>{each?.attempts}</span>
            </div>
            <div>
              <span className="body-row-header">Last Attempt At: </span>
              <span>
                {getDate(each.last_attempt)}
                <span className="annotation" style={{ marginLeft: '5px' }}>
                  {getTime(each.last_attempt)}
                </span>
              </span>
            </div>
          </div>
        ))}
        {webhooksModalState.visible && (
          <WebhooksDetailsModal
            webhook={webhooksModalState.data}
            close={() =>
              setWebhooksModalState(prevState => ({
                ...prevState,
                visible: false,
                data: {}
              }))
            }
            savedState={savedState}
            navigateToPayInOrPayoutDetailPage={navigateToPayInOrPayoutDetailPage}
          />
        )}
      </section>
    );
  };

  return (
    <>
      {WebhookNotifications()}
      <PaginationComponent
        currentPage={paging?.current}
        pagingTotalItems={paging?.total_items}
        limitAction={limitAction}
        pageSize={paging?.page_size}
        action={pagingAction}
        scrollToTop={scrollToTop}
        annotation="items"
      />
    </>
  );
}

WebhooksTableComponent.defaultProps = {
  scrollToTop: () => null
};

export default WebhooksTableComponent;
