import React, { useState } from 'react';
import { useQuery } from 'react-query';

import Modal from '+containers/Dashboard/Shared/Modal';
import Table from '+containers/Dashboard/Shared/Table';
import { useFeedbackHandler, useSearchQuery } from '+hooks';
import APIRequest from '+services/api-services';
import { capitalize, filteredOutObjectProperty, getDate, getTime, logError, queriesParams } from '+utils';

import UserIcon from '+assets/theme/img/user-icon.png';

import './index.scss';

const api = new APIRequest();
type TeamMemberType = {
  id: number;
  firstname: string;
  lastname: string;
  email: string;
  phonenumber: string;
  merchant: {
    name: string;
    id: number;
  };
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
};

const AllMerchantTeamMember = () => {
  const { feedbackInit } = useFeedbackHandler();
  const searchQuery = useSearchQuery();
  const page = searchQuery.value.page ?? 1;
  const limit = searchQuery.value.limit ?? 10;
  const [selectedRow, setSelectedRow] = useState<TeamMemberType | null>(null);
  const valuesToBeRemoved = [queriesParams.tab, queriesParams.page, queriesParams.limit, queriesParams.subTab];
  const sortingParams = {
    ...filteredOutObjectProperty(searchQuery.value, valuesToBeRemoved)
  };

  const { data: merchants, isFetching } = useQuery(
    ['MERCHANTS_TEAM_MEMBER', page, limit, sortingParams],
    () => api.getMerchantsAllTeamMember(Number(page), Number(limit), sortingParams),
    {
      refetchOnMount: true,
      onError: error => {
        logError(error);
        feedbackInit({
          message: 'There has been an error fetching all merchants team members, Please try again',
          type: 'danger'
        });
      }
    }
  );

  const allMerchantsTeam = merchants?.data ?? [];

  const MerchantsTeamMember = {
    annotation: 'merchant team members',
    className: '--merchant-team-members-table',
    emptyStateHeading: 'No Merchants yet',
    data: allMerchantsTeam?.data || [],
    emptyStateMessage: 'There are no Merchants',
    rowURL: '/dashboard/merchant-team',
    rowKey: 'id',
    fields: (each: TeamMemberType) => ({
      data: {
        team_members: (
          <>
            <img alt="business avatar" src={UserIcon} style={{ width: '25px' }} />
            <span id="merchant-name" style={{ fontWeight: 500 }}>
              {capitalize(`${each.firstname} ${each.lastname}`)}
            </span>
          </>
        ),
        email: <span className="lighter">{each.email ?? 'N/A'}</span>,
        phone_number: each.phonenumber ?? 'N/A',
        merchant_id: each.merchant.id,
        parent_merchant: <span style={{ color: '#007bff', fontWeight: 500 }}>{capitalize(each.merchant.name) || 'N/A'}</span>
      }
    })
  };

  const handleShowDetails = (row: TeamMemberType) => {
    setSelectedRow(row);
  };

  return (
    <div className="row">
      <div className="col-sm-12">
        <div className="element-box-tp">
          <Table
            annotation="Members"
            className={MerchantsTeamMember.className}
            loading={isFetching}
            data={MerchantsTeamMember?.data || []}
            renderFields
            hasPagination
            rowKey={MerchantsTeamMember?.rowKey}
            tableHeadings={['Team Member', 'Email', 'Phone', 'Merchant ID', 'Parent Merchant']}
            current={Number(page)}
            totalItems={allMerchantsTeam.paging?.total_items}
            pageSize={allMerchantsTeam.paging?.page_size}
            tableWrapperClassName="table-responsive table-wrapper general-merchant-table-wrapper"
            type="merchant-team-members"
            filterKeywordPlaceholder="Search..."
            actionFn={currentPage => searchQuery.setQuery({ page: String(currentPage) })}
            limitAction={currentLimit => searchQuery.setQuery({ limit: String(currentLimit) })}
            emptyStateHeading={MerchantsTeamMember?.emptyStateHeading || ''}
            emptyStateMessage={MerchantsTeamMember.emptyStateMessage || ''}
            filterShowExport={false}
            showDateFilter={false}
            filterHasAdvancedFilter={false}
            rowFn={handleShowDetails}
          >
            {MerchantsTeamMember?.fields}
          </Table>
        </div>
        <Modal
          close={() => {
            setSelectedRow(null);
          }}
          visible={!!selectedRow}
          size="md"
          heading="Team Member Details"
          description="Here are more details of this team member"
          firstButtonText="Close"
          hideSecondButton
          content={
            selectedRow ? (
              <div>
                <span className="merchant-team-member-details-modal">
                  <p>Name</p>
                  <p>{`${selectedRow?.firstname} ${selectedRow?.lastname}`}</p>
                </span>
                <span className="merchant-team-member-details-modal">
                  <p>Email</p>
                  <p>{selectedRow.email}</p>
                </span>
                <span className="merchant-team-member-details-modal">
                  <p>Phone Number</p>
                  <p>{selectedRow.phonenumber ?? 'N/A'}</p>
                </span>
                <span className="merchant-team-member-details-modal">
                  <p>Merchant ID</p>
                  <p>{selectedRow.merchant.id ?? 'N/A'}</p>
                </span>
                <span className="merchant-team-member-details-modal">
                  <p>Parent Merchant</p>
                  <p>{selectedRow?.merchant?.name}</p>
                </span>
                <span className="merchant-team-member-details-modal">
                  <p>Date Joined</p>
                  <span>
                    <p>{getDate(selectedRow.createdAt)} </p>
                    <p className="annotation">{getTime(selectedRow.createdAt)}</p>
                  </span>
                </span>
              </div>
            ) : null
          }
        />
      </div>
    </div>
  );
};

export default AllMerchantTeamMember;
