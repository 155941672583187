import React, { useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { AxiosError } from 'axios';

import LoadingPlaceholder from '+containers/Dashboard/Shared/LoadingPlaceHolder';
import Modal from '+containers/Dashboard/Shared/Modal';
import { useFeedbackHandler, useSearchQuery, useSetUserAccess } from '+hooks';
import APIRequest from '+services/api-services';
import { capitalizeFirst, history, logError } from '+utils';

import AccessRequestCountries from '../../Components/AccessRequestCountries';
import DiligenceFormViewer from '../../Components/DiligenceFormViewer';
import HeroSection from '../../Components/HeroSection';

import './index.scss';

import AddMerchantsModal from '../../Components/AddMerchantsModal';

const api = new APIRequest(process.env.REACT_APP_MIDDLEWARE_API_BASE);

const tabs = [
  { name: 'Individual KYC Use Cases', key: 'kyc' },
  { name: 'Business KYB Use Cases', key: 'kyb' }
];

export default function RequestDetails() {
  const { id } = useParams<{ id: string }>();
  const { feedbackInit } = useFeedbackHandler();
  const searchQuery = useSearchQuery();
  const activeTab = searchQuery.value.tab || tabs[0].key;

  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [confirmationModalType, setConfirmationModalType] = useState<'approve' | 'decline'>('decline');
  const [addMerchantModal, setAddMerchantModal] = useState(false);

  const updateIdentityAccessRequest = useMutation((data: { action: string }) => api.updateIdentityAccessRequest(id, data), {
    onError: (error: AxiosError) => {
      logError(error);
      feedbackInit({
        message: (error as { response: { data: { message: string } } }).response?.data.message,
        componentLevel: true,
        type: 'danger'
      });
    },
    onSuccess: () => {
      feedbackInit({ message: `Merchant ${confirmationModalType}d successfully`, componentLevel: true, type: 'success' });
    }
  });

  const {
    data,
    isLoading,
    refetch: refetchMerchantData
  } = useQuery(`IDENTITY_ACCESS_${id.toUpperCase()}`, () => api.getIdentityAccessRequest(id), {
    onError: (e: AxiosError) => {
      logError(e);
      feedbackInit({
        message: "There has been an error getting this merchant's information",
        type: 'danger'
      });
    }
  });

  const merchantData = {
    name: data?.name,
    status: data?.status
  };

  const displayedData = data?.display_data || {};

  const confirmationModal = (type: 'approve' | 'decline') => {
    const content = {
      heading: `${capitalizeFirst(type)} access request?`,
      description:
        type === 'approve'
          ? `Please confirm that you want to approve ${merchantData.name}’s access request to identity.`
          : `Please confirm your decision to decline ${merchantData.name}’s access request for approval. This decision is final, and the merchant will be required to submit a new request.`,
      content: '',
      firstButtonText: 'Cancel',
      secondButtonText: `Yes, ${capitalizeFirst(type)}`,
      secondButtonAction: async () => {
        await updateIdentityAccessRequest.mutateAsync({ action: type });
        refetchMerchantData();
      },
      secondButtonColor: type === 'decline' ? '#F32345' : '',
      equalFooterBtn: true,
      completedHeading: `Service ${type.charAt(0).toUpperCase() + type.slice(1)}d!`,
      completedDescription: `You have successfully ${type}d the service for this merchant.`,
      secondaryCompletedModal: true
    };

    return content;
  };

  const handleCloseConfirmationModal = () => {
    setShowConfirmationModal(false);
  };

  const isTabDisabled = (tab: string) => {
    return !Object.entries(displayedData[tab] || {}).length;
  };

  const diligenceFormURL = data?.data?.due_diligence_form;

  return (
    <div className="ird">
      <div className="content-i">
        <div className="content-box">
          {isLoading ? (
            <div className="row">
              <div className="col-sm-12">
                <LoadingPlaceholder type="text" content={1} section={4} />
              </div>
            </div>
          ) : (
            <>
              <div className="row">
                <div className="col-sm-12">
                  <button
                    type="button"
                    className="btn btn-link mb-2 identity__back-btn"
                    onClick={() => history.push('/dashboard/identity?tab=requests')}
                  >
                    <i className="os-icon os-icon-arrow-left7" />
                    <span>Back to Requests</span>
                  </button>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12">
                  <HeroSection
                    title={
                      <>
                        {`Identity Service for ${capitalizeFirst(merchantData?.name || '') || 'N/A'}`}{' '}
                        <span className={`identity__status ${merchantData?.status}`}>{capitalizeFirst(merchantData?.status)}</span>
                      </>
                    }
                    buttonClassName="remove-margin-top"
                    button={
                      !['approved', 'declined'].includes(merchantData?.status)
                        ? [
                            {
                              name: 'Decline',
                              action: () => {
                                setShowConfirmationModal(true);
                                setConfirmationModalType('decline');
                              },
                              color: 'secondary'
                            },
                            {
                              name: 'Approve Request',
                              action: () => {
                                setAddMerchantModal(true);
                              },
                              color: 'primary'
                            }
                          ]
                        : undefined
                    }
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12">
                  <DiligenceFormViewer url={diligenceFormURL} />
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12 mt-3 mb-2">
                  <div className="os-tabs-w">
                    <div className="os-tabs-controls os-tabs-complex">
                      <ul className="nav nav-tabs">
                        {tabs.map(tab => {
                          return (
                            <li className="nav-item" key={tab.key}>
                              <button
                                type="button"
                                onClick={() => searchQuery.setQuery({ tab: tab.key }, true)}
                                onKeyDown={() => searchQuery.setQuery({ tab: tab.key }, true)}
                                className={`nav-link details--custom--button identity-tab ${tab.key === activeTab ? 'active' : ''}`}
                                disabled={isTabDisabled(tab.key)}
                              >
                                <span className="tab-label">{tab.name}</span>
                              </button>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12 mb-4 ird__header">{`Selected ${
                  activeTab === 'kyc' ? 'individual (KYC)' : 'business (KYB)'
                } verification use cases by region/country`}</div>
              </div>
              <div className="row">
                <div className="col-sm-12">
                  <AccessRequestCountries title="ID Verification & Lookup" data={displayedData[activeTab]} />
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      {showConfirmationModal && (
        <Modal {...confirmationModal(confirmationModalType)} close={() => handleCloseConfirmationModal()} size="md" />
      )}
      {addMerchantModal && (
        <AddMerchantsModal
          close={() => setAddMerchantModal(false)}
          merchantName={merchantData.name}
          countries={Object.keys(displayedData[activeTab] || {})}
          refetchMerchant={refetchMerchantData}
          koraId={data?.kora_id}
        />
      )}
    </div>
  );
}
