import { QueryKey, useMutation, useQuery, useQueryClient } from 'react-query';
import { AxiosError } from 'axios';

import APIRequest from '+services/api-services';
import { ErrorResponse, IKoraRateResponse, IMarkupResponse, IProviderRateResponse, IUpdateMarkUpData } from '+types';
import { logError } from '+utils';

const api = new APIRequest();

const createFeedbackInitValues = () => ({
  title: '',
  message: '',
  type: 'danger',
  callback: undefined,
  statusCode: undefined,
  isActive: false,
  isClosable: true,
  componentLevel: true
});

const useFetchData = <TData>({
  queryKey,
  queryFn,
  enabled,
  feedbackInit,
  closeFeedback,
  onSuccess,
  onError
}: {
  queryKey: QueryKey;
  queryFn: () => Promise<TData>;
  enabled: boolean;
  feedbackInit: (values: { [key: string]: string | boolean | undefined }) => void;
  closeFeedback?: () => void;
  onSuccess?: (data: TData) => void;
  onError?: (error: AxiosError) => void;
}) => {
  return useQuery<TData>(queryKey, queryFn, {
    keepPreviousData: true,
    enabled,
    onSuccess: (result: TData) => {
      if (onSuccess) {
        onSuccess(result);
      }

      if (closeFeedback) {
        setTimeout(() => {
          closeFeedback();
        }, 5000);
      }
    },
    onError: error => {
      logError(error);

      const feedbackValues = createFeedbackInitValues();

      const message =
        (error as AxiosError<ErrorResponse>)?.response?.data?.message ||
        `There has been an error fetching ${queryKey[0]} data. Please check your input and try again.`;

      feedbackInit({
        ...feedbackValues,
        message
      });

      if (closeFeedback) {
        setTimeout(() => {
          closeFeedback();
        }, 5000);
      }
      if (onError) {
        onError(error as AxiosError);
      }
    }
  });
};

export const useFetchKoraRate = (
  fromCurrency: string,
  toCurrency: string,
  newMarkUpValue: string,
  feedbackInit: (values: { [key: string]: string | boolean | undefined }) => void,
  closeFeedback: () => void
) =>
  useFetchData<IKoraRateResponse>({
    queryKey: ['KORA_RATE', fromCurrency, toCurrency, newMarkUpValue],
    queryFn: () => api.fetchKoraRate(fromCurrency, toCurrency, newMarkUpValue),
    enabled: !!newMarkUpValue,
    feedbackInit,
    closeFeedback
  });

export const useFetchLastSetMarkup = (
  fromCurrency: string,
  toCurrency: string,
  feedbackInit: (values: { [key: string]: string | boolean | undefined }) => void,
  closeFeedback: () => void
) =>
  useFetchData<IMarkupResponse>({
    queryKey: ['MARK_UP', fromCurrency, toCurrency],
    queryFn: () => api.getMarkUp(fromCurrency, toCurrency),
    enabled: true,
    feedbackInit,
    closeFeedback
  });

export const useFetchProviderRate = (
  fromCurrency: string,
  toCurrency: string,
  feedbackInit: (values: { [key: string]: string | boolean | undefined }) => void
) =>
  useFetchData<IProviderRateResponse>({
    queryKey: ['PROVIDER_RATE', fromCurrency, toCurrency],
    queryFn: () => api.fetchProviderRate(fromCurrency, toCurrency),
    enabled: true,
    feedbackInit,
    onSuccess: () => {
      const queryClient = useQueryClient();
      queryClient.invalidateQueries(['MARK_UP']);
    }
  });

export const useUpdateMarkUp = (
  refetchMarkup: () => void,
  feedbackInit: (values: { [key: string]: string | boolean | undefined }) => void,
  refetchProviderRate: () => void
) => {
  return useMutation<void, unknown, IUpdateMarkUpData>((updateMarkUpData: IUpdateMarkUpData) => api.updateMarkUp(updateMarkUpData), {
    onSuccess: () => {
      refetchMarkup();
      refetchProviderRate();
    },
    onError: error => {
      logError(error);
      const feedbackValues = createFeedbackInitValues();
      feedbackInit({
        ...feedbackValues,
        message: (error as AxiosError<ErrorResponse>)?.response?.data?.message || 'There has been an error adding Markup'
      });
    }
  });
};
