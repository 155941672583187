import React, { useState } from 'react';
import { useQuery } from 'react-query';

import { useFeedbackHandler, useSetUserAccess } from '+hooks';
import APIRequest from '+services/api-services';
import Icon from '+shared/Icons';
import { CurrencyType, IError } from '+types';
import { getOrdinal, isAllowed, logError } from '+utils';

import BillingCycleEditorModal from './BillingCycleEditorModal';

const api = new APIRequest();

export default function BillingCyclePanel({ currency }: { currency: CurrencyType }) {
  const { feedbackInit, closeFeedback } = useFeedbackHandler();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const { data, refetch } = useQuery(['VIRTUAL_CARDS_BILLING_CYCLE', currency], () => api.getBillingCycle(currency), {
    onError: (error: IError) => {
      logError(error);
      feedbackInit({
        message: error.response?.data?.message || 'Unable to retrieve billing cycle. Please try again',
        type: 'danger',
        componentLevel: true
      });
      setTimeout(() => {
        closeFeedback();
      }, 5000);
    }
  });

  const userAccess = useSetUserAccess();
  const canUpdateCycle = !!isAllowed(userAccess, ['card_issuance_billing_cycle.update']);

  return (
    <>
      <div className="config-wrapper">
        <div className="d-flex justify-content-between align-items-start">
          <div className="config-wrapper__heading">
            <h4 className="config-wrapper__title">Default Billing Cycle/Date:</h4>
            <p className="config-wrapper__description">
              This sets the date a billing cycle ends, when the issuing merchant would receive an invoice and be charged for the service.
            </p>
          </div>

          <div>
            <div className="d-flex align-items-center billing-cycle-wrapper">
              <p>
                Billed: <strong>{`Every ${getOrdinal(data?.day)} day`}</strong>
              </p>

              <div>
                <button
                  onClick={() => setModalIsOpen(true)}
                  type="button"
                  className="btn btn--ghost p-0 d-flex justify-content-center align-items-center"
                  disabled={!canUpdateCycle}
                >
                  <Icon name="editPen" width={17} height={17} />
                  Change Billing Cycle/Date
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {modalIsOpen ? (
        <div className="mt-0">
          <BillingCycleEditorModal refetch={refetch} onClose={() => setModalIsOpen(false)} currentData={{ ...data }} currency="USD" />
        </div>
      ) : null}
    </>
  );
}
