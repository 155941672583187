export const TeamPermissionColumnType = {
  view: 'view',
  export: 'export',
  manage: 'manage'
} as const;

type RoleType = 'admin' | 'owner' | 'developer' | 'operations' | 'support';
export type TeamMemberType = {
  email: string;
  user_id: number;
  role_id: number;
  completed: number;
  expires_at: string;
  userRole: {
    id: number;
    name: Capitalize<RoleType>;
    slug: RoleType;
    permissions: Record<string, 'view' | 'export' | 'manage'>;
    category: 'system';
  };
  user: {
    first_name: string;
    last_name: string;
    last_login: string;
    two_factor_enabled: number;
    status: 'active' | 'inactive' | 'locked';
  };
  status: 'invite_accepted' | 'active';
};
